import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/seo/mainseo'
import PageBannerOG from '../components/pagebanner/pagebanner'
import MainNav from '../components/navigation/mainnav'
import PageBannerMessage from '../components/pagebanner/pagebannermessage'
import Container from '../components/container'
import CenteringDiv from '../components/centeringdiv'
import IntroDiv from '../components/introdiv'
import ContentContainer from '../components/contentcontainer'
import ContentBlock from '../components/contentblock'
import CourseContentBlock from '../components/coursecontentblock'
import ContentBlockBackground from '../components/contentblockbackground'
import LevelSelection from '../components/coursetable/levelselection'
import CourseContainer from '../components/coursecontainer'
import CourseSummary from '../components/coursetable/coursesummary'
import DateSelection from '../components/coursetable/dateselection'
import LocalDateSelection from '../components/coursetable/localdateselection'
import FeatureCard from '../components/cards/featurecard'
import FeatureCardList from '../components/cards/featurecardlist'
import ListCards from '../components/cards/listcards'
import PageBlock from '../components/pageblock'
import PageContact from '../components/forms/pagecontact-sf'
import BreadCrumbs  from '../components/breadcrumbs'
import BreadCrumbsTopLevel  from '../components/breadcrumbstoplevel'
import SiteMap from '../components/sitemap'
import Interruptor from '../components/genericinterruptor'
import Headline from '../components/headline'
import styled from 'styled-components';

const LocalDateSelectionMobile = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;
const LocalDateSelectionDesktop = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;
const CoursesPageV2 = ({ data, html }) => {
  const { markdownRemark: post } = data
  var delink = "de";
  var enlink = "en";
  const metaUrlalt="https://www.berlitz-augsburg.de/"
  const metaUrl="https://www.berlitz-augsburg.de"

  if (post.frontmatter.language === "de"){
    delink = `${metaUrl}${post.fields.slug}`
    enlink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
  } else if (post.frontmatter.language === "en") {
    delink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
    enlink = `${metaUrl}${post.fields.slug}`
  }
  const [course, setCourse] = useState("");

  const [selectedOptionFromChild, setSelectedOptionFromChild] = useState('');
  const handleSelectOption = (option) => {
    setSelectedOptionFromChild(option);
  };

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSchedule, setSelectedSchedule] = useState({
    schedule_de: '',
    schedule_en: '',
    focus_en: '',
    focus_de: '',
    costs: '',
  });
  
  const handleDateChange = (newSelectedDateObj) => {
    const { date, schedule } = newSelectedDateObj;
    setSelectedDate(date);
    setSelectedSchedule({
      schedule_de: schedule?.schedule_de || '',
      schedule_en: schedule?.schedule_en || '',
      focus_en: schedule?.focus_en || '',
      focus_de: schedule?.focus_de || '',
      costs: schedule?.costs || '',
      length_de: schedule?.length_de || '',
      length_en: schedule?.length_en || '',
    });
  };
  useEffect(() => {
    setSelectedDate('');
    setSelectedSchedule('');
  }, [selectedOptionFromChild]);

  return (
    <Layout currentlang={post.frontmatter.language} langlinkde={delink} langlinken={enlink}>
      <Seo language={post.frontmatter.language}
           pageslug={post.fields.slug}
           title={post.frontmatter.seo.metatitle}
           imgtitle={post.frontmatter.title}
           imgtitleen={post.frontmatter.imagetitle}
           description={post.frontmatter.seo.metadescription}
           lastbreadcrumb={post.frontmatter.lastbreadcrumb}
           pagetype={post.frontmatter.templateKey}
           altlanglink={post.frontmatter.seo.altlanguagelink}
           hreflangalt={post.frontmatter.seo.hreflangalt}
      />
      {post.frontmatter.language === "de" &&
        <PageBannerOG title={post.frontmatter.title} alt={post.frontmatter.displaytitle} isHome={post.frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language}/>
          <PageBannerMessage supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.language === "en" &&
        <PageBannerOG title={post.frontmatter.imagetitle} alt={post.frontmatter.displaytitle} isHome={post.frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language}/>
          <PageBannerMessage supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.toplevelpage === true &&
      <BreadCrumbsTopLevel current={post.frontmatter.lastbreadcrumb}
                   currentlang={post.frontmatter.language}
                   breadcrumbcolor={post.frontmatter.breadcrumbcolor} 
      />
      }
      {post.frontmatter.toplevelpage === false &&
      <BreadCrumbs breadcrumbs={post.frontmatter.breadcrumbs} 
                  current={post.frontmatter.lastbreadcrumb}
                  currentlang={post.frontmatter.language}
                  level={post.frontmatter.toplevelpage}
                  breadcrumbcolor={post.frontmatter.breadcrumbcolor} 
      />
      }

      <section role="main">
        {post.frontmatter.pagebody &&
          <ContentBlock background={post.frontmatter.pagebodybackground}>
            <Container>
              <CenteringDiv>
                <ContentContainer dangerouslySetInnerHTML={{ __html: post.html }}/>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.featurecardlist &&        
          <ContentBlock>
            <Container>
                <FeatureCardList cardtitle={post.frontmatter.featurecardlist.cardtitle}
                                 carditems={post.frontmatter.featurecardlist.listitems}
                                 cardimage={post.frontmatter.featurecardlist.featurecardimage.childImageSharp.fixed}
                                 imageposition={post.frontmatter.featurecardlist.imageposition}
                                 buttons={post.frontmatter.featurecardlist.buttons}
                                 languageselect={post.frontmatter.featurecardlist.languageselect}
                                 singlebutton={post.frontmatter.featurecardlist.singlebutton}
                                 singlebuttonlink={post.frontmatter.featurecardlist.singlebuttonlink}
                />
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.pagetype =="coursesv2" &&
          <CourseContentBlock background="true">
            <Container>
              <CourseContainer>
                <Headline currentlang={post.frontmatter.language}
                          germanHeadline={post.frontmatter.germanheadline}
                          englishHeadline={post.frontmatter.englishheadline}
                />
                <LevelSelection onSelectOption={handleSelectOption} 
                                initialSelectedOption="Level 1 / A1" 
                                currentlang={post.frontmatter.language} 
                                selectedLanguage={post.frontmatter.courselistname}
                                decoursetype={post.frontmatter.decoursetype}
                                encoursetype={post.frontmatter.encoursetype}
                                languageinterest={post.frontmatter.enlanguageinterest}>

                </LevelSelection>
                <LocalDateSelectionMobile>
                  <LocalDateSelection onChange={handleDateChange}
                              currentlang={post.frontmatter.language}  
                              selectedLanguage={post.frontmatter.courselistname} 
                              selectedLevel={selectedOptionFromChild}
                              decoursetype={post.frontmatter.decoursetype}
                              encoursetype={post.frontmatter.encoursetype}
                              dedatesheadline={post.frontmatter.dedatesheadline}
                              endatesheadline={post.frontmatter.endatesheadline}>
                  </LocalDateSelection>
                </LocalDateSelectionMobile>
                <CourseSummary selectedInput={selectedOptionFromChild} 
                               selectedDate={selectedDate}
                               currentlang={post.frontmatter.language}  
                               selectedLanguage={post.frontmatter.enlanguageinterest}
                               langDe={post.frontmatter.delanguageinterest} 
                               schedule_en={selectedSchedule.schedule_en}
                               schedule_de={selectedSchedule.schedule_de}
                               focus_en={selectedSchedule.focus_en}
                               focus_de={selectedSchedule.focus_de}
                               costs={selectedSchedule.costs}
                               length_de={selectedSchedule.length_de}
                               length_en={selectedSchedule.length_en}
                               leadsource={post.fields.slug}
                               decoursetype={post.frontmatter.decoursetype}
                               encoursetype={post.frontmatter.encoursetype}/>                         
              </CourseContainer>
              <LocalDateSelectionDesktop>
                <LocalDateSelection onChange={handleDateChange}
                              currentlang={post.frontmatter.language}  
                              selectedLanguage={post.frontmatter.courselistname} 
                              selectedLevel={selectedOptionFromChild}
                              decoursetype={post.frontmatter.decoursetype}
                              encoursetype={post.frontmatter.encoursetype}
                              dedatesheadline={post.frontmatter.dedatesheadline}
                              endatesheadline={post.frontmatter.endatesheadline}>
                </LocalDateSelection>
              </LocalDateSelectionDesktop>
            </Container>
          </CourseContentBlock>
        }
        {post.frontmatter.pagetype =="onlinecourses" &&
          <CourseContentBlock background="true">
            <Container>
              <CourseContainer>
                <Headline currentlang={post.frontmatter.language}
                          germanHeadline={post.frontmatter.germanheadline}
                          englishHeadline={post.frontmatter.englishheadline}
                />
                <LevelSelection onSelectOption={handleSelectOption} 
                                initialSelectedOption="Level 1 / A1" 
                                currentlang={post.frontmatter.language} 
                                selectedLanguage={post.frontmatter.enlanguageinterest}>
                </LevelSelection>
                <LocalDateSelectionMobile>
                  <DateSelection onChange={handleDateChange}
                              currentlang={post.frontmatter.language}  
                              selectedLanguage={post.frontmatter.enlanguageinterest} 
                              selectedLevel={selectedOptionFromChild}
                              decoursetype={post.frontmatter.decoursetype}
                              encoursetype={post.frontmatter.encoursetype}
                              dedatesheadline={post.frontmatter.dedatesheadline}
                              endatesheadline={post.frontmatter.endatesheadline}>
                                
                  </DateSelection>
                </LocalDateSelectionMobile>
                <CourseSummary selectedInput={selectedOptionFromChild} 
                              selectedDate={selectedDate}
                              currentlang={post.frontmatter.language}  
                              selectedLanguage={post.frontmatter.enlanguageinterest}
                              langDe={post.frontmatter.delanguageinterest} 
                              schedule_en={selectedSchedule.schedule_en}
                              schedule_de={selectedSchedule.schedule_de}
                              focus_en={selectedSchedule.focus_en}
                              focus_de={selectedSchedule.focus_de}
                              costs={selectedSchedule.costs}
                              length_de={selectedSchedule.length_de}
                              length_en={selectedSchedule.length_en}
                              leadsource={post.fields.slug}
                              decoursetype={post.frontmatter.decoursetype}
                              encoursetype={post.frontmatter.encoursetype}
                              dedatesheadline={post.frontmatter.dedatesheadline}
                              endatesheadline={post.frontmatter.endatesheadline}
                />                 
              </CourseContainer>
              <LocalDateSelectionDesktop>
              <DateSelection onChange={handleDateChange}
                            currentlang={post.frontmatter.language}  
                            selectedLanguage={post.frontmatter.enlanguageinterest} 
                            selectedLevel={selectedOptionFromChild}
                            decoursetype={post.frontmatter.decoursetype}
                            encoursetype={post.frontmatter.encoursetype}
                            dedatesheadline={post.frontmatter.dedatesheadline}
                            endatesheadline={post.frontmatter.endatesheadline}>
                              
              </DateSelection>
                </LocalDateSelectionDesktop>       
            </Container>
          </CourseContentBlock>
        }
        {post.frontmatter.interruptortext  &&
          <Interruptor interruptorText={post.frontmatter.interruptortext}/>
        }
        {post.frontmatter.postinterruptor &&
          <ContentBlock>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  <h3>{post.frontmatter.postinterruportheadline}</h3>
                  {post.frontmatter.postinterruptor.paragraphs.map(item => (
                          <p>{item.paragraph}</p>
                  ))} 
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {(post.frontmatter.pagetype ==="coursesv2" || post.frontmatter.pagetype === "onlinecourses") && 
        <PageContact currentlang={post.frontmatter.language}
                     formname={post.frontmatter.title}
                     leadsource={post.fields.slug}
                     pagetype={post.frontmatter.pagetype}
                     deLanguageInterest={post.frontmatter.delanguageinterest}
                     enLanguageInterest={post.frontmatter.enlanguageinterest}
                     selectedcourse={selectedOptionFromChild} 
                     selectedLevel={selectedOptionFromChild}
                     selectedDate={selectedDate}
                     schedule_en={selectedSchedule.schedule_en}
                     schedule_de={selectedSchedule.schedule_de}
                     anchor="kursanfrage"
                     anchoren="course-inquiry"
                     decoursetype={post.frontmatter.decoursetype}
                     encoursetype={post.frontmatter.encoursetype}
                     contactformheadline={post.frontmatter.contactformheadline}      
        />   
        }           
      </section>
    </Layout>
  )
}

export default CoursesPageV2

export const pageQuery = graphql`
  query CoursesPageV2ByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        templateKey
        pagetype
        title
        imagetitle
        languageinterest
        enlanguageinterest
        delanguageinterest
        courselistname
        decoursetype
        encoursetype
        germanheadline
        englishheadline
        dedatesheadline
        endatesheadline
        contactformheadline
        subfolder
        toplevelpage
        language
        seo {
          metatitle
          metadescription
          altlanguagelink
          hreflangalt
        }
        title       
        displaytitle
        supertitle
        contactheading
        introtext
        interruptortext
        postinterruportheadline
        postinterruptor {
          paragraphs {
            paragraph
          }
        } 
        sitemap
        breadcrumbs {
          crumbitem
          link
        }
        lastbreadcrumb
        breadcrumbcolor
        introbackground
        cardwithlink
        pagebody
        pagebodybackground
        leadinheading
        leadinabout {
          paragraph
        }
        introheading
        introcontentblocksabout {
          introparagraph
        }
        introcontent {
          heading
          introparagraphs {
            paragraph
          }
          subheader
          list {
            listitem
          }
        }
        introcontentbold {
          bold
          paragraphs {
            paragraph
          }
        }
        levels
        checkout
        pagecontentblocks {
          heading
          text
        }
        featurecard {
          cardtitle
          cardtext
          cardlink
          cardbutton
          cardimage {
            childImageSharp {
              fixed(width: 500, height: 500, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurecardlist {
          cardtitle
          listitems {
            listitem
          }
          buttons {
            button
            buttonlink
          }
          singlebutton
          singlebuttonlink
          imageposition
          languageselect
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurecardlistreverse {
          cardtitle
          listitems {
            listitem
          }
          imageposition
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurecardlisttwo {
          cardtitle
          listitems {
            listitem
          }
          singlebutton
          singlebuttonlink
          imageposition
          languageselect
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        cardsintro {
          heading
          paragraphs {
            paragraph
          }
        }
        cards {
          image {
            childImageSharp {
              fixed(width: 480, height: 320, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          cardtitle
          link
          button
          text
        }
        tests {
          testtitle
          testdescription
        }
        listcards {
          cardtitle
          cardtexts {
            cardtext
          }
          cardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        richcards {
          heading
          background
          cards {
            link
            text
            subheading
            listitems {
              listitem
            }
            image {
              childImageSharp {
                fixed(width: 480, height: 320, quality: 80) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        buttoncards {
          link
          text
        }
        products {
          producttitle
          productsubtitle
        }
      }
    }
    
  }
  
`
