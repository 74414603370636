import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ModalWindow from '../modalwindow'
import BerlitzLevels from '../levels'
import CourseDetails from './coursedetails'

const LevelSelectionContainer = styled.div`
    background-color: rgb(246, 246, 246);
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100%;
    max-width: 100%;
    display: block;
    h3 {
      font-size: 18px;
      color: rgb(45, 47, 61);
      font-weight: 400;
      line-height: 1.6;
      margin: 0px;
    }
    @media only screen and (min-width: 62em) {
    {
        flex-basis: 58.3333%;
        max-width: 58.3333%;
        display: block;
      }
    }
`;

const RadioWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    {
      width: 100%;
    }
  }

`;

const RadioCard = styled.div`
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  flex-basis: 33.333333333333336%;
  max-width: 33.333333333333336%;
  display: block;
  padding-right: 16px;
  margin-bottom: 16px;
  position: relative;

  :nth-child(3n) {
    padding-right: 0px;
  }
  @media (max-width: 992px) {
     {
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
      padding-right: 0px;
      margin-bottom: 16px;
    }
  }
  
`;

const RadioInput = styled.input`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
 

  &:checked + label {
    border: 2px solid rgb(0, 79, 239);
    border-radius: 4px;
    transition: 50ms;
  }
  &:checked + label + div {
    background: green;
  }
`;

const RadioText = styled.div`
  p {
    color: rgb(45, 47, 61);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 8px;
  }
`;

const SubText = styled.p`
    font-size: 16px !important;
    line-height: 1.6 !important;
    font-weight: 300 !important;
    margin-bottom: 0px !important;
`;

const IconDiv = styled.div`
  border-radius: 50%;
  opacity: 0;
  position: absolute;
  right: -12px;
  top: -12px;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  opacity: 1;
  transform: scale(1);
  display: none;
  background-color: rgb(255, 255, 255);
`;
const IconSpan = styled.span`
  color: rgb(17, 169, 65);
  font-size: 24px;
  display: flex;
`;


const RadioLabel = styled.label`
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  padding: 24px;
  position: relative;
  transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 1;

  @media (min-width: 768px) {
    &:hover, 
    &:focus {
      box-shadow: rgba(0, 0, 0, 0.09) 0px 16px 32px;
      transform: translateY(-4px);
    }
  }
`;
const ModalButton = styled.button`
  color: rgb(0, 79, 239);
  cursor: pointer;
  margin-bottom: 24px;
  text-decoration: underline; 
`;
 
const RadioComponent = ({ onSelectOption, initialSelectedOption, selectedLanguage, currentlang, decoursetype, encoursetype, languageinterest }) => {
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

  useEffect(() => {
    onSelectOption(selectedOption);
    if (selectedOption !== initialSelectedOption) {
      const anchorElement = document.getElementById('coursedates');
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start', duration: 6000  });
      }
    }
  }, [onSelectOption, selectedOption]);

  const handleOptionChange = (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    onSelectOption(newSelectedOption);
    const iconDiv = event.target.nextSibling.querySelector('div');
    if (iconDiv) {
      iconDiv.style.display = event.target.checked ? 'inline-block' : 'none';
    }
      // Scroll to anchor if the selected option is Level 1
    if (newSelectedOption === 'Level 1 / A1') {
      const anchorElement = document.getElementById('coursedates');
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start', duration: 6000 });
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
    <LevelSelectionContainer>
      {currentlang ==="de" &&
        <div>
          <h3>Wählen Sie Ihr Sprachlevel aus</h3>
          <ModalButton onClick={handleOpenModal}>Welcher Level passt zu mir?</ModalButton>
          <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal}>
            <BerlitzLevels currentlang={currentlang}></BerlitzLevels>
          </ModalWindow>
        </div>
      }
      {currentlang ==="en" &&
        <div>
          <h3>Please select your previous knowledge</h3>
          <ModalButton onClick={handleOpenModal}>Which level is best for me?</ModalButton>
          <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal}>
            <BerlitzLevels currentlang={currentlang}></BerlitzLevels>
          </ModalWindow>
        </div>
      }
      <RadioWrapper>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 1"
            name="Level 1"
            value="Level 1 / A1"
            checked={selectedOption === 'Level 1 / A1'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 1">
            <IconDiv style={{ display: selectedOption === 'Level 1 / A1' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 1</p>
              <SubText>CEF Level A1</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 2"
            name="options"
            value="Level 2 / A2.1"
            checked={selectedOption === 'Level 2 / A2.1'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 2">
            <IconDiv style={{ display: selectedOption === 'Level 2 / A2.1' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 2</p>
              <SubText>CEF Level A2.1</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 3"
            name="options"
            value="Level 3 / A2.2"
            checked={selectedOption === 'Level 3 / A2.2'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 3">
            <IconDiv style={{ display: selectedOption === 'Level 3 / A2.2' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 3</p>
              <SubText>CEF Level A2.2</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 4"
            name="options"
            value="Level 4 / A2.3"
            checked={selectedOption === 'Level 4 / A2.3'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 4">
            <IconDiv style={{ display: selectedOption === 'Level 4 / A2.3' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 4</p>
              <SubText>CEF Level A2.3</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 5"
            name="options"
            value="Level 5 / B1.1"
            checked={selectedOption === 'Level 5 / B1.1'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 5">
            <IconDiv style={{ display: selectedOption === 'Level 5 / B1.1' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 5</p>
              <SubText>CEF Level B1.1</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 6"
            name="options"
            value="Level 6 / B1.2"
            checked={selectedOption === 'Level 6 / B1.2'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 6">
            <IconDiv style={{ display: selectedOption === 'Level 6 / B1.2' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 6</p>
              <SubText>CEF Level B1.2</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 7"
            name="options"
            value="Level 7 / B1.3"
            checked={selectedOption === 'Level 7 / B1.3'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 7">
            <IconDiv style={{ display: selectedOption === 'Level 7 / B1.3' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 7</p>
              <SubText>CEF Level B1.3</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 8"
            name="options"
            value="Level 8 / B2.1"
            checked={selectedOption === 'Level 8 / B2.1'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 8">
            <IconDiv style={{ display: selectedOption === 'Level 8 / B2.1' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 8</p>
              <SubText>CEF Level B2.1</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        <RadioCard>
          <RadioInput
            type="radio"
            id="Level 9"
            name="options"
            value="Level 9 / B2.2"
            checked={selectedOption === 'Level 9 / B2.2'}
            onChange={handleOptionChange}
          />
          <RadioLabel htmlFor="Level 9">
            <IconDiv style={{ display: selectedOption === 'Level 9 / B2.2' ? 'inline-block' : 'none' }}>
              <IconSpan>
                <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
              </IconSpan>
            </IconDiv>
            <RadioText>
              <p>Berlitz Level 9</p>
              <SubText>CEF Level B2.2</SubText>
            </RadioText>
          </RadioLabel>
        </RadioCard>
        {languageinterest ==="German"  &&
          <RadioCard>
            <RadioInput
              type="radio"
              id="Level 10"
              name="options"
              value="Level 10 / C1.1"
              checked={selectedOption === 'Level 10 / C1.1'}
              onChange={handleOptionChange}
            />
            <RadioLabel htmlFor="Level 10">
              <IconDiv style={{ display: selectedOption === 'Level 10 / C1.1' ? 'inline-block' : 'none' }}>
                <IconSpan>
                  <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
                </IconSpan>
              </IconDiv>
              <RadioText>
                <p>Berlitz Level 10</p>
                <SubText>CEF Level C1.1</SubText>
              </RadioText>
            </RadioLabel>
          </RadioCard>
        }
        {languageinterest ==="German"  &&
          <RadioCard>
            <RadioInput
              type="radio"
              id="Level 11"
              name="options"
              value="Level 10 / C1.2"
              checked={selectedOption === 'Level 10 / C1.2'}
              onChange={handleOptionChange}
            />
            <RadioLabel htmlFor="Level 11">
              <IconDiv style={{ display: selectedOption === 'Level 10 / C1.2' ? 'inline-block' : 'none' }}>
                <IconSpan>
                  <svg fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path class="check-circle-1_svg__path0" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path></svg>
                </IconSpan>
              </IconDiv>
              <RadioText>
                <p>Berlitz Level 10</p>
                <SubText>CEF Level C1.2</SubText>
              </RadioText>
            </RadioLabel>
          </RadioCard>
        }
      </RadioWrapper>
    </LevelSelectionContainer>
  );
};

export default RadioComponent;