
import React from 'react'
import styled from 'styled-components'

const ContentModule = styled.section`
    background-color: rgb(255, 255, 255);
    background-color: ${props => props.backgroundcolor ? 'rgb(246, 246, 246)' : 'rgb(255, 255, 255)'};
    padding: 32px 0px;
    
    h2 {
        text-align: ${props => props.alignment ? 'center' : 'left'};
    }
    @media (min-width:576px) {
        padding: 64px 0px;
        padding-top: ${props => props.landingpagetrue ? '0px' : '64px'};
        padding-top: ${props => props.iscards ? '0px' : '64px'};
        padding-bottom: ${props => props.iscards ? '0px' : '64px'};
    }  
`;

const CourseContentBlock = props => (
    <ContentModule backgroundcolor={props.background} landingpagetrue={props.landingpage} iscards={props.iscards} alignment={props.aligncenter}>
        {props.children}
    </ContentModule>
  )
  
   
export default CourseContentBlock