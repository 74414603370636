import React from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 47, 61, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 5px;
  width: 1400px;
  text-align: right;
  min-height: 800px; /* Adjust the max-height as needed */
  max-height: 800px;
  overflow-y: auto; /* Enable vertical scrolling */
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: sticky;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  background-color: rgb(255, 255, 255);
  height: 64px;
  z-index: 10;

  @media (min-width: 576px) {
      height: 48px;
  }
`;

const CloseButton = styled.button`
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;

  span {
    color: rgb(0, 79, 239);
    font-size: 14px;
  }
`;

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <ModalBackground onClick={handleClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <CloseButton onClick={handleClose}>
            <span>
              <svg viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path fill="currentColor" d="M14.3 12.179a.25.25 0 010-.354l9.263-9.262A1.5 1.5 0 0021.439.442L12.177 9.7a.25.25 0 01-.354 0L2.561.442A1.5 1.5 0 00.439 2.563L9.7 11.825a.25.25 0 010 .354L.439 21.442a1.5 1.5 0 002.122 2.121l9.262-9.263a.25.25 0 01.354 0l9.262 9.263a1.5 1.5 0 002.122-2.121z"></path></svg>
            </span>
          </CloseButton>
        </ModalHeader>
        {children}
      </ModalContent>
    </ModalBackground>
  );
};

export default Modal;