import React from "react";
import styled from "styled-components";

const HeadingWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 32px;

  h2 {
    text-align: center;
    font-size: 32px;
    color: rgb(45, 47, 61);
    font-weight: 500;
    line-height: 1.3;
  }
  @media (max-width: 992px) {
    {
      h2 {
        font-size: 24px;
      }
    }
  }
`;

const Headline = ({ currentlang, germanHeadline, englishHeadline }) => {
  return (
    <HeadingWrapper>
      {currentlang === "de" ? (
        <h2>{germanHeadline}</h2>
      ) : (
        <h2>{englishHeadline}</h2>
      )}
    </HeadingWrapper>
  );
};

export default Headline;