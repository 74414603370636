import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import blocoursesData from '../../../config/blocourses.yaml';

const RadioWrapper = styled.div`
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    display: block;
    padding-left: 46px;

    h3 {
      font-size: 18px;
      margin: 0px;
      color: rgb(45, 47, 61);
      font-weight: 400;
      line-height: 1.6;
      margin-bottom: 12px;
      padding-bottom: 12px;
    }
      @media (max-width: 992px) {
        flex-basis: 100%;
        max-width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        margin-top: 20px;
        margin-bottom: 30px;
        
        h3 {
          font-size: 18px;
        }
        span {
          font-size: 16px;
        }
      }
`;
const RadioContainer = styled.div`
  margin-top: 8px;
`;
const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
`;

const HiddenRadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const CustomRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgb(180, 180, 180);
  background-color: rgb(255, 255, 255);
  margin-right: 10px;
  @media (max-width: 992px) {
    {
      width: 22px;
      height: 22px;
    }
  }
`;

const InnerCircle = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  background-color: rgb(0, 79, 239);
  display: ${({ checked }) => (checked ? 'block' : 'none')};
`;

const MobileDisplay = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;
const DesktopDisplay = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;

  const DateSelection = ({ selectedLanguage, currentlang, selectedLevel = 'Level 1 / A1', onChange, decoursetype, encoursetype, dedatesheadline, endatesheadline }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDatesByLevel, setSelectedDatesByLevel] = useState({});

  useEffect(() => {
    // Reset selected date when selected level changes
    setSelectedDate('');
  }, [selectedLevel]);

  const handleOptionChange = (event) => {
    const newSelectedDate = event.target.value;
    setSelectedDate(newSelectedDate);

    // Update selected dates by level
    setSelectedDatesByLevel((prevSelectedDatesByLevel) => ({
      ...prevSelectedDatesByLevel,
      [selectedLevel]: newSelectedDate,
    }));

    const selectedSchedule = getScheduleForSelectedDate(selectedLanguage, selectedLevel, newSelectedDate);
    onChange({ date: newSelectedDate, schedule: selectedSchedule });
  };

  const getScheduleForSelectedDate = (language, level, date) => {
    const selectedLanguageData = blocoursesData.languages.find(
      (lang) => lang.name === language
    );

    if (!selectedLanguageData) {
      return null;
    }

    const selectedLevelData = selectedLanguageData.levels.find(
      (lvl) => lvl.name === level
    );

    if (!selectedLevelData) {
      return null;
    }

    const selectedDateData = selectedLevelData.dates.find(
      (d) => d.date === date
    );

    return selectedDateData
    ? {
        schedule_de: selectedDateData.schedule_de,
        schedule_en: selectedDateData.schedule_en,
        focus_de: selectedDateData.focus_de,
        focus_en: selectedDateData.focus_en,
        costs: selectedDateData.costs,
        length_de: selectedDateData.length_de,
        length_en: selectedDateData.length_en,
      }
    : null;
  };

  const mapSelectedLevel = (level) => {
    switch (level) {
      case 'Level 1 / A1':
        return 'A1';
      case 'Level 2 / A2.1':
        return 'A2.1';
      case 'Level 3 / A2.2':
        return 'A2.2';
      case 'Level 4 / A2.3':
        return 'A2.3';
      case 'Level 5 / B1.1':
        return 'B1.1';
      case 'Level 6 / B1.2':
        return 'B1.2';
      case 'Level 7 / B1.3':
        return 'B1.3';
      case 'Level 8 / B2.1':
        return 'B2.1';
      case 'Level 9 / B2.2':
        return 'B2.2';
      case 'Level 10 / C1.1':
        return 'C1.1';
      case 'Level 10 / C1.2':
        return 'C1.2';
      default:
        return level;
    }
  };
  
  const renderDatesForSelectedLevel = () => {
    const selectedLanguageData = blocoursesData.languages.find(
      (language) => language.name === selectedLanguage
    );

    if (!selectedLanguageData) {
      return null;
    }

    const selectedLevelData = selectedLanguageData.levels.find(
      (level) => level.name === selectedLevel
    );

    if (!selectedLevelData) {
      return null;
    }

    const mappedLevel = mapSelectedLevel(selectedLevel); 

    return selectedLevelData.dates.map((date, index) => (
      <RadioContainer>
        {currentlang === "de" &&
          <React.Fragment>
            <DesktopDisplay>
              <RadioLabel key={index} encoursetype={decoursetype}>
                <HiddenRadioInput
                  type="radio"
                  value={date.date}
                  checked={selectedDate === date.date}
                  onChange={handleOptionChange}
                />
                <CustomRadio>
                  <InnerCircle checked={selectedDate === date.date} />
                </CustomRadio>
                <span class="bluespan"><strong>{date.date}</strong></span> <span>&nbsp; - {decoursetype}&nbsp;{date.focus_de}{date.focus_de !== "Geschäftsdeutsch" ? "kurs" : ""}&nbsp;{mappedLevel}&nbsp;({date.schedule_de})</span>
              </RadioLabel>
            </DesktopDisplay>
          <MobileDisplay>
            <RadioLabel key={index} encoursetype={decoursetype}>
                <HiddenRadioInput
                  type="radio"
                  value={date.date}
                  checked={selectedDate === date.date}
                  onChange={handleOptionChange}
                />
                <CustomRadio>
                  <InnerCircle checked={selectedDate === date.date} />
                </CustomRadio>
                <span>{decoursetype}&nbsp;{date.focus_de}{date.focus_de !== "Geschäftsdeutsch" ? "kurs" : ""}&nbsp;{mappedLevel} <br></br>
                <span class="bluespan"><strong>{date.date}</strong></span> ({date.schedule_de})</span>
            </RadioLabel>
          </MobileDisplay>
        </React.Fragment>
        }
        {currentlang === "en" &&
          <React.Fragment>
            <DesktopDisplay>
              <RadioLabel key={index} encoursetype={encoursetype}>
                <HiddenRadioInput
                  type="radio"
                  value={date.date}
                  checked={selectedDate === date.date}
                  onChange={handleOptionChange}
                />
                <CustomRadio>
                  <InnerCircle checked={selectedDate === date.date} />
                </CustomRadio>
                <span class="bluespan"><strong>{date.date}</strong></span> <span>&nbsp; - {encoursetype}&nbsp;{date.focus_en}&nbsp;Course&nbsp;{mappedLevel}&nbsp;({date.schedule_en})</span>
              </RadioLabel>
            </DesktopDisplay>
          <MobileDisplay>
            <RadioLabel key={index} encoursetype={decoursetype}>
                <HiddenRadioInput
                  type="radio"
                  value={date.date}
                  checked={selectedDate === date.date}
                  onChange={handleOptionChange}
                />
                <CustomRadio>
                  <InnerCircle checked={selectedDate === date.date} />
                </CustomRadio>
                <span>{encoursetype}&nbsp;{date.focus_en}&nbsp;Course&nbsp;{mappedLevel} <br></br>
                <span class="bluespan"><strong>{date.date}</strong></span> ({date.schedule_en})</span>
            </RadioLabel>
          </MobileDisplay>
        </React.Fragment>
        }
      </RadioContainer>
    ));
  };

  return (
    <RadioWrapper>
      <div id="coursedates"></div>
      {currentlang === 'de' && (
        <div>
            <h3>{dedatesheadline}</h3>
          <span>Startdatum / Zeitplan</span>
          {selectedLevel && renderDatesForSelectedLevel()}
        </div>
      )}
      {currentlang === 'en' && (
        <div>
            <h3>{endatesheadline}</h3>
          <span>Start date / Schedule</span>
          {selectedLevel && renderDatesForSelectedLevel()}
        </div>
      )}
    </RadioWrapper>
  );
};

export default DateSelection;
