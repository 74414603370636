import React from 'react'
import styled from 'styled-components'


const OfferSection = styled.section`
background-color: rgb(100, 59, 162);

`;

const OfferDiv = styled.div`
    display: inherit;
    width: 100%;
    max-width: 1312px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 32px 0px;
    @media (max-width: 992px) {
        {
            padding: 0px 24px;
            padding-top: 24px;
        }
      }
`;

const OfferContainer= styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    justify-content: center;

    @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
    }

    @media only screen and (min-width:62em) {
        flex-basis: 75%;
        max-width: 75%;
        display: flex;
        justify-content: center;
      }

      @media (min-width:768px) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      @media (max-width: 992px) {
        {
            margin: 0px 0px 24px;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
        }
      }
`;
const OfferHeader = styled.p`
    text-align: left;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    margin: 0;

    @media (min-width:576px) {
        font-size: 18px;
    }
    @media (max-width: 992px) {
        {
          font-size: 16px;
          flex-basis: 100%;
          max-width: 100%;
          padding-left: 0px;
        }
      }
`;



const Interruptor = ({ interruptorText }) => (
    <OfferSection>
        <OfferDiv>
            <OfferContainer>
                <OfferHeader>{interruptorText}</OfferHeader>
            </OfferContainer>
        </OfferDiv>
    </OfferSection>
  )
  
  
  
export default Interruptor