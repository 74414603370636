import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  padding-top: 16px;

  hr {
    border-width: 1px 0px 0px;
    border-style: solid none none;
    border-color: rgb(227, 227, 227) currentcolor currentcolor;
    border-image: none;
    margin: 0px;
    margin-top: 40px;
    margin-bottom: 30px;
    height: 1px;
  }
`;

const TableHeader = styled.h4`
  text-align: left;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  background-color: ${(props) => (props.isBlue ? 'rgb(229, 237, 253)' : '#fff')};
`;

const TableCell = styled.td`
  font-size: 14px;
  padding: 12px;
  text-align: left;
  font-weight: 400;
  color: rgb(29, 29, 29);
`;

const CourseDetailsTable = ({ selectedInput, selectedLanguage }) => {
  return (
    <TableWrapper>
      <TableHeader>Kursdetails</TableHeader>
      <Table>
        <tbody>
          <TableRow isBlue={false}>
            <TableCell>Kursname</TableCell>
            <TableCell>	Live Online Gruppenkurs Englisch ({selectedInput})</TableCell>
          </TableRow>
          <TableRow isBlue={true}>
            <TableCell>Sprache</TableCell>
            <TableCell>{selectedLanguage}</TableCell>
          </TableRow>
          <TableRow isBlue={false}>
            <TableCell>Unterrichtseinheiten</TableCell>
            <TableCell>40</TableCell>
          </TableRow>
          <TableRow isBlue={true}>
            <TableCell>Dauer</TableCell>
            <TableCell>20 Wochen</TableCell>
          </TableRow>
          <TableRow isBlue={false}>
            <TableCell>Teilnehmer</TableCell>
            <TableCell>	3-6</TableCell>
          </TableRow>
        </tbody>
      </Table>
      <hr></hr>
    </TableWrapper>
  );
};

export default CourseDetailsTable;