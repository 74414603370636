import React from 'react';
import styled from 'styled-components';

const CourseContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-flow: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  align-items: flex-start;
  @media only screen and (min-width: 0em) {
    {
      box-pack: center;
      justify-content: center;
    }
  }
`;


export default CourseContainer;
