import styled from 'styled-components'

const IntroDiv = styled.div`
    box-sizing: border-box;

    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    text-align: center;

    @media only screen and (min-width:62em) {
        justify-content: center;

    }

    p {
        text-align: center;
    }

    h2 {
        margin-bottom: 12px;
    }
    @media (max-width:576px) {
        h2 {
          text-align: center;
        }
      }
`;

export default IntroDiv