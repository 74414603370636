import React from 'react'
import styled, { css } from 'styled-components'
import Row from './container'
import { Link } from "gatsby"

const CrumbList = styled.ol`
    white-space: nowrap;
    overflow-x: auto;
    text-transform: capitalize;
    margin-bottom: 0;
    padding-left: 0;

`;
const CrumbItem = styled.li`
    display: inline-flex;
    flex-shrink: 0;
    font-size: 14px;
    color:
    rgb(97, 98, 109);
    margin-bottom: 0 !important;

    a {
    cursor: pointer;
    color:
    rgb(97, 98, 109);
    text-decoration: none;
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    background: none;
    }

    :last-child {
        color: rgb(29, 29, 29);
        padding-right: 16px;
        font-weight: 400;
        text-transform: none;
    }

    :after {
        content: "";
        margin: 8px;
        border-right: 1px solid 
        rgba(29, 29, 29, 0.1);
    }

    :last-child:after {
        border-right: 0;
    }

    a:hover, a:active {
        color: rgb(0, 79, 239);
    }

    a, span {
        font-size: 14px;
    }
    span {
        padding: 12px 8px;
    }
`;

const CrumbItemSpan = styled.span`
    margin: 0;
    text-align: left;
`;

const CrumbWrapper= styled.div`
    background-color: ${props => props.breadcrumbcolor ? 'rgb(252, 252, 252)' : 'rgb(255, 255, 255)'};
    ${props =>
        props.contactpage &&
        css`
        background: rgb(246, 246, 246);
    `
}
`;
const Breadcrumbs = props => (

    <CrumbWrapper breadcrumbcolor={props.breadcrumbcolor}>
        <Row>
            <CrumbList>
                <CrumbItem>
                    <CrumbItemSpan>
                        { props.currentlang === 'de' &&
                            <Link to="/">Home</Link>
                        }
                        { props.currentlang === 'en' &&
                            <Link to="/en/">Home</Link>
                        }
                    </CrumbItemSpan>
                </CrumbItem>
                    <React.Fragment>
                    {props.breadcrumbs.map(item => (
                        <CrumbItem><CrumbItemSpan><Link to={"https://www.berlitz-augsburg.de/" + item.link + "/"}>{item.crumbitem}</Link></CrumbItemSpan></CrumbItem>
                    ))} 
                    </React.Fragment>
                <CrumbItem>
                    <CrumbItemSpan>
                        {props.current}
                    </CrumbItemSpan>
                </CrumbItem>
            </CrumbList>
        </Row>
    </CrumbWrapper>            
)

export default Breadcrumbs